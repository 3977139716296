import { createApp, ref, watch } from 'vue'
import '@sennder/design-system-core'
import {
  getFeatureFlags,
  initializeVue as initLaunchDarkly,
} from '@sennder/senn-node-feature-flags-frontend'
import { loadStyles, themeProvider, Themes } from '@sennder/design-system-core'
import { envOverrides } from '@sennder/shell-utilities'
import { LogLevel, Shell } from '@sennder/microfrontend-registry'
import {
  IOrcasSharedData,
  SharedDataType,
} from '@sennder/senn-node-microfrontend-interfaces'

import '@/assets/tailwind.global.css'
import '@sennder/shell-utilities/dist/style.css'

import App from '@/App.vue'
import router from '@/router'
import { ENV, LD_KEY, isLocalEnv } from '@/common/config'
import { registerPlugins } from '@/plugins'
import { translationProvider } from '@/services/translationProvider'
import { loadUserData } from '@/store'
import { logger } from '@/services/logger/loggers'
import { registerModalManager } from '@/components/modal-manager'
import { getCookieConsent } from '@/services/cookies'
import { startTracking } from '@/services/tracking'
import { getLaunchDarklyAnonymousContext } from './services/launchDarkly'
import { carrierProfilePublicService } from '@/services/carrierProfilePublicService'
import { initRouter } from './router/routes'
import { microfrontends } from './config/microfrontends'
import { getMfContext } from './config/getMfContext'
import { getStateData } from './store/getters'
import { initSessionReplay, stopSessionReplay } from '@/services/analytics'

export const shell = ref<Shell<IOrcasSharedData>>()

async function initialize() {
  window.onerror = function (message, url, line, column, error) {
    logger.error('[Orcas shell - global error]', { error })
  }

  carrierProfilePublicService.cleanupLocalstorage()

  shell.value = new Shell(
    SharedDataType.ORCAS,
    getMfContext,
    ENV,
    ENV === 'prod' ? LogLevel.OFF : LogLevel.ALL
  )

  if (!LD_KEY) {
    throw new Error('LD_KEY not found')
  }

  if (isLocalEnv()) {
    envOverrides.load(Object.values(microfrontends))
  }

  registerModalManager()

  await Promise.all([
    initLaunchDarkly(LD_KEY, await getLaunchDarklyAnonymousContext()),
    translationProvider.initialize(),
    loadUserData(),
  ])
}

async function checkCookieConsent() {
  try {
    const consent = await getCookieConsent()
    if (consent.ANALYTICS) {
      startTracking()
    }
    return consent
  } catch (error) {
    logger.error(
      `[orcas-shell - checkCookieConsent] Error checking cookie consent`,
      { error }
    )
  }
}

async function start() {
  await loadStyles('body')
  themeProvider('html', Themes.Light)
  // don't load data and mount the app until user gives cookie consent
  await checkCookieConsent()

  try {
    await initialize()
  } catch (error) {
    logger.error(`Orcas shell init`, { error })
  } finally {
    const app = createApp(App)
    const { unmount } = app

    const stopWatchData = watch(getStateData, () => {
      shell.value?.onSharedDataChange()
    })

    const stopWatchAmplitudeReplayFlag = watch(
      () => getFeatureFlags()['ENABLE_AMPLITUDE_SESSION_REPLAY'],
      (enable: boolean) => {
        if (enable) {
          initSessionReplay()
        } else {
          stopSessionReplay()
        }
      },
      {
        immediate: true,
      }
    )

    app.unmount = (...args) => {
      unmount(...args)
      stopWatchData()
      stopWatchAmplitudeReplayFlag()
      shell.value?.destroy()
    }

    await initRouter()
    app.use(router)

    registerPlugins(app)

    app.mount('#app')
  }
}

start()
